.copyright {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #5a5a5a;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .copyright {
    width: 90%;
  }
}