/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,700&display=swap'); */

.login_container{
    /* make it centered */
    position: relative;
    top: 50%;
    /* left: 50%; */
    transform: translate(0, -50%);
    margin: auto;
    width: 100%;
    max-width: 650px;
    display: flex;
    padding: 28px;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
}
.login_container2{
    /* make it centered */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    width: 100%;
    max-width: 650px;
    display: flex;
    padding: 28px;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
}
.logHeader {
    border-bottom: 1px solid silver;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
    margin-bottom: 10px;
  }
  .logHeader img {
    width: 200px;
  }
  @media only screen and (max-width: 768px) {
    .logHeader img {
      width: 150px;
    }
    .logHeader h2 {
      font-size: 1.2rem;
    }
  }
  .logHeader h2 {
    background-image: linear-gradient(45deg, #5fa3f7, #32547e);
    background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  }
.form-login{
    /* background-image: linear-gradient(45deg, #000, #383737); */
    box-shadow: 0 2px 2px rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    max-width: 430px;
    padding: 30px;
    width: 100%;
}
.login_header {
    font-size: 30px;
    font-weight: 800;
    background-image: linear-gradient(45deg, #5fa3f7, #32547e);
    background-size: 100%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}
.login_form{
    margin-top: 30px;

}
.login_form .input{
    height: 50px;
    width: 100%;
    margin-top: 30px;
    position: relative;
}
.input input,
.input-btn button{
    height: 100%;
    width: 100%;
    border: none;
    font-size: 16px;
    font-weight: 400;
    border-radius: 6px;
}
.input-btn{
    height: 50px;
    width: 100%;
    margin-top: 30px;
    padding: 3px;
    background: linear-gradient(45deg, #5fa3f7, #32547e);
    border-radius: 50px;
}
.input-btn button{
    color: #fff;
    border-radius: 50px;
    background-color: #000;
    background-image: linear-gradient(45deg, #000, #383737)
}
.input-btn button:hover{
    color: #fff;
    cursor: pointer;
    background-image: none;
    background-color: #2c2c2c;
    transition: 0.3s ease-in-out;
}

.input input{
    padding: 0 15px;
    border: 1px solid #cacaca;
}
.input-link{
    text-align: center;
    margin-top: 10px;
}
.input-link span,
.input-link a{
    font-size: 14px;
    font-weight: 400;
    color: #232836;
}
.input-link a{
    text-decoration: none;
    color: #5fa3f7;
}
.input-link a:hover{
    text-decoration: underline;
}
.icon1{
    font-size: 18px;
    position: absolute;
    color: #8b8b8b;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    padding: 5px;

}