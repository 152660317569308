.optHero {
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url(../../../../public/optTrade/hero.webp);
  background-repeat: no-repeat;
  background-position: center center;
}

.optHeroMain {
  width: 70%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding-top: 96px;
  padding-bottom: 85px;
}

.optHeroMainTitle {
  color: #0D71FF;
  font-size: 60px;
  font-family: var(--webull-font-family-extra-bold, OpenSans-Extrabold, Arial, sans-serif) !important;
  font-weight: 900;
  line-height: 72px;
  margin-bottom: 12px;
  letter-spacing: 0;
}

.optHeroMainDesc {
  color: #636872;
  width: 450px;
  font-size: 20px;
  font-family: var(--webull-font-family-normal, OpenSans-Regular, Arial, sans-serif) !important;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 24px;
  letter-spacing: 0;
}

.optHeroItem {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  justify-content: flex-start;
}

.optHeroItemImg {
  width: 28px;
  height: 28px;
}

.optHeroItemText {
  color: #12161E;
  font-size: 18px;
  font-family: var(--webull-font-family-semi-bold, OpenSans-SemiBold, Arial, sans-serif) !important;
  font-weight: 400;
  line-height: 28px;
  margin-left: 12px;
  letter-spacing: 0;
}

.optHeroBtn {
  color: #FFFFFF;
  width: 296px;
  cursor: pointer;
  height: 68px;
  display: flex;
  font-size: 20px;
  background: #0D71FF;
  margin-top: 40px;
  text-align: center;
  align-items: center;
  font-family: var(--webull-font-family-bold, OpenSans-Bold, Arial, sans-serif) !important;
  font-weight: 700;
  line-height: 28px;
  border-radius: 12px;
  letter-spacing: 0;
  justify-content: center;
  transition: background 0.3s ease;
}

.optHeroBtn:hover {
  background: #5da0fd;
}

.optHeroImg {
  width: 738px;
  height: auto;
}

/* Media Queries */

/* For tablets and smaller devices */
@media (max-width: 768px) {
  .optHeroMain {
    width: 90%;
    flex-direction: column-reverse;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
  }

  .optHeroMainTitle {
    font-size: 48px;
    line-height: 56px;
  }

  .optHeroMainDesc {
    width: 100%;
    font-size: 18px;
    line-height: 26px;
  }

  .optHeroItems {
    margin-top: 20px;
  }

  .optHeroImg {
    width: 100%;
    margin-bottom: 20px;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .optHeroMain {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .optHeroMainTitle {
    font-size: 36px;
    line-height: 44px;
  }

  .optHeroMainDesc {
    font-size: 16px;
    line-height: 24px;
  }

  .optHeroItemText {
    font-size: 16px;
  }

  .optHeroBtn {
    width: 100%;
    height: 56px;
    font-size: 18px;
    margin-top: 20px;
  }

  .optHeroImg {
    width: 100%;
    margin-bottom: 10px;
  }
}
