.signup {
  display: flex;
  min-height: 100vh;
  font-family: "OpenSans-Regular", Arial, Helvetica, sans-serif;;
}
.signupLeft {
  width: 50%;
  background-color: #2b5ef5;
}
.signupLeft a{
  color: #fff;
  text-decoration: underline;
}
.signupRight {
  width: 50%;
  background-color: #fff;
}
.signupLogoImg {
  height: 60px;
}
.signupLogoText {
  height: 60px;
}
.signupLogo {
  align-items: center;
  justify-content: center;
  padding: 10px 80px;
}
.signupText {
  color: #FFFFFF;
  margin: 24% auto 0;
  font-size: 36px;
  text-align: center;
  font-family: var(--webull-font-family-bold, OpenSans-Bold, Arial, sans-serif) !important;
  font-weight: 700;
  line-height: 46px;
  white-space: pre-wrap;
}
.signupText2 {
  color: #FFFFFF;
  margin: 12px 0;
  opacity: 0.8;
  font-size: 20px;
  text-align: center;
  line-height: 28px;
}
.signupText3 {
  color: #fff;
  width: 500px;
  margin: 20px auto 10px;
  opacity: 0.4;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
}
.signupText4 {
  color: #fff;
  width: 500px;
  margin: 20px auto 10px;
  opacity: 0.4;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
}