.user-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 2rem;
}
.user-card {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1.3rem;
  width: 500px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.5s ease-in-out;
  color: #000;
}

.admin input {
  width: 100%;
  height: 3rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 1rem;
  margin-bottom: 20px;
}

.admin button {
  width: 100%;
  height: 3rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 1rem;
  margin-bottom: 20px;
}