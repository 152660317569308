
.register_container{
  max-width: 650px;
  padding: 28px;
  margin: auto auto;
  /* background-image: linear-gradient(45deg, #000, #383737); */
  box-shadow: 0 2px 2px rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  color: #000000;
  /* box-shadow: 0 15px 20px gray; */
}
.register_container h2{
  font-size: 32px;
  font-weight: 600;
  text-align: left;
  background-image: linear-gradient(45deg, #5fa3f7, #32547e);
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  padding-bottom: 8px;
  
}
.regHeader {
  border-bottom: 1px solid silver;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
}
.regHeader img {
  width: 200px;
}
@media only screen and (max-width: 768px) {
  .regHeader img {
    width: 150px;
  }
  .regHeader h2 {
    font-size: 1.6rem;
  }
}
.register_container h3{
  font-size: 28px;.register{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120vh;
  }
  .register_container{
    max-width: 650px;
    padding: 28px;
    margin: auto 28px;
    /* background-image: linear-gradient(45deg, #000, #383737); */
    box-shadow: 0 2px 2px rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    color: #fff;
    /* box-shadow: 0 15px 20px gray; */
  }
  @media screen and (max-width: 768px) {
    .register_container {
      padding: 20px;
    }
  }
  .register_container h2{
    font-size: 32px;
    font-weight: 600;
    text-align: left;
    background-image: linear-gradient(45deg, #ffffff, #40b811);
    background-size: 100%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    padding-bottom: 8px;
    
  }
  .regHeader {
    border-bottom: 1px solid silver;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
  }
  .regHeader img {
    width: 500px;
  }
  @media only screen and (max-width: 768px) {
    .regHeader img {
      width: 300px;
    }
    .regHeader h2 {
      font-size: 1.6rem;
    }
  }
  .register_container h3{
    font-size: 28px;
    text-align: left;
    color: #fff;
    margin-top: 30px;
  }
  .register_container p{
    color: rgb(224, 218, 210);
    font-weight: 400;
    margin-bottom: 15px;
    
  }
  .regcontent{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
  }
  .inputBox{
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    padding-bottom: 15px;
  }
  .inputBox span{
    color: red;
  }
  .inputBox:nth-child(2n){
    justify-content: end;
  }
  .inputBox label{
    width: 95%;
    color: #fff;
    font-weight: bold;
    margin: 5px 0;
  }
  .inputBox input{
    height: 40px;
    width: 95%;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
    /* color: #fff; */
  }
  .inputBox input:focus{
    border: 2px solid #40b811;
  }
  .inputBox select{
    height: 40px;
    width: 95%;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
    /* color: #fff; */
  }
  .inputBox input placeholder{
    color: #232836;
  }
  .inputBox input:is(:focus, :valid){
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }
  .alert{
    font-size: 14px;
    font-style: italic;
    color: #fff;
    margin: 5px 0;
    padding: 10px;
  }
  .alert span{
    color: red;
  }
  .alert a{
    text-decoration: none;
  }
  .alert a:hover{
    text-decoration: underline;
  }
  .buttonContainer{
    margin: 15px o;
  }
  
  .buttonContainer button{
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    display: block;
    font-size: 20px;
    color: black;
    border: none;
    border-radius: 5px;
    /* background-color: bisque; */
  }
  .buttonContainer button:hover{
   background-color: grey;
   color: #fff;
   transition: all ease-in-out .5s;
  }
  .input-link{
    text-align: center;
    margin-top: 10px;
  }
  .input-link span,
  .input-link a{
    font-size: 14px;
    font-weight: 400;
    color: #fff;
  }
  .input-link a{
    text-decoration: none;
    color: #40b811;
  }
  .input-link a:hover{
    text-decoration: underline;
  }
  
  
  @media only screen and (max-width: 768px) {
    .register {
        height: 150vh;
    }
    .register_container {
        padding: 20px;
    }
    .inputBox {
        width: 100%;
    }
    .inputBox:nth-child(2n) {
        justify-content: start;
    }
    .buttonContainer button {
        font-size: 18px;
    }
  }
  @media only screen and (max-width: 480px) {
    .register {
        height: 180vh;
    }
  }
  text-align: left;
  color: #000;
  margin-top: 30px;
}
.register_container p{
  color: rgb(224, 218, 210);
  font-weight: 400;
  margin-bottom: 15px;
  
}
.regcontent{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0;
}
.inputBox{
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  padding-bottom: 15px;
}
.inputBox span{
  color: red;
}
.inputBox:nth-child(2n){
  justify-content: end;
}
.inputBox label{
  width: 95%;
  color: #000;
  font-weight: bold;
  margin: 5px 0;
}
.inputBox input{
  height: 40px;
  width: 95%;
  padding: 0 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  /* color: #fff; */
}
.inputBox input:focus{
  border: 2px solid #2b5ef5;
}
.inputBox select{
  height: 40px;
  width: 95%;
  padding: 0 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  /* color: #fff; */
}
.inputBox input placeholder{
  color: #232836;
}
.inputBox input:is(:focus, :valid){
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); */
}
.alert{
  font-size: 14px;
  font-style: italic;
  color: #000;
  margin: 5px 0;
  padding: 10px;
}
.alert span{
  color: red;
}
.alert a{
  text-decoration: none;
}
.alert a:hover{
  text-decoration: underline;
}
.buttonContainer{
  margin: 15px o;
}

.buttonContainer button{
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  display: block;
  font-size: 20px;
  color: black;
  border: none;
  border-radius: 5px;
  /* background-color: bisque; */
}
.buttonContainer button:hover{
 background-color: grey;
 color: #000;
 transition: all ease-in-out .5s;
}
.input-link{
  text-align: center;
  margin-top: 10px;
}
.input-link span,
.input-link a{
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.input-link a{
  text-decoration: none;
  color: #0171d3;
}
.input-link a:hover{
  text-decoration: underline;
}


@media only screen and (max-width: 768px) {
  .register {
      height: 150vh;
  }
  .register_container {
      padding: 20px;
  }
  .inputBox {
      width: 100%;
  }
  .inputBox:nth-child(2n) {
      justify-content: start;
  }
  .buttonContainer button {
      font-size: 18px;
  }
}
@media only screen and (max-width: 480px) {
  .register {
      height: 180vh;
  }
}