.optTradeBody1 {
  padding: 10px;
  width: 70%;
  display: flex;
  text-align: center;
  align-items: center;
  padding-top: 120px;
  flex-direction: column;
  padding-bottom: 121px;
  margin: auto;
}

.optTradeBody1Title {
  color: #12161E;
  font-size: 44px;
  text-align: center;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 12px;
  letter-spacing: 0;
}

.optTradeBody1Desc {
  color: #636872;
  width: 100%;
  max-width: 1000px;
  font-size: 20px;
  text-align: center;
  font-family: var(--webull-font-family-normal, OpenSans-Regular, Arial, sans-serif) !important;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 52px;
  letter-spacing: 0;
  margin: 0 auto; /* Center align the description */
}

.optTradeBody1Items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px; /* Add space between items */
}

.optTradeBody1Item {
  flex: none;
  width: 380px;
  max-width: 100%;
  height: 257px;
  display: flex;
  background: rgba(13, 113, 255, 0.04);
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
}

.optTradeBody1ItemImg {
  width: 44px;
  height: 44px;
  margin-top: 41px;
  margin-bottom: 20px;
}

.optTradeBody1Txt1 {
  color: #12161E;
  font-size: 20px;
  text-align: center;
  font-family: var(--webull-font-family-bold, OpenSans-Bold, Arial, sans-serif) !important;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 12px;
  letter-spacing: 0;
}

.optTradeBody1Txt2 {
  color: #636872;
  width: 300px;
  font-size: 18px;
  text-align: center;
  font-family: var(--webull-font-family-normal, OpenSans-Regular, Arial, sans-serif) !important;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
}

/* Media Queries */

/* For tablets and smaller devices */
@media (max-width: 768px) {
  .optTradeBody1 {
    width: 90%;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .optTradeBody1Title {
    font-size: 36px;
    line-height: 48px;
  }

  .optTradeBody1Desc {
    width: 100%;
    font-size: 18px;
    line-height: 26px;
    max-width: none; /* Reset max-width */
  }

  .optTradeBody1Items {
    gap: 10px; /* Reduce space between items */
  }

  .optTradeBody1Item {
    width: 100%;
    height: auto;
    padding: 20px;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .optTradeBody1 {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .optTradeBody1Title {
    font-size: 28px;
    line-height: 36px;
  }

  .optTradeBody1Desc {
    font-size: 16px;
    line-height: 24px;
  }

  .optTradeBody1Items {
    gap: 10px; /* Reduce space between items */
    flex-direction: column; /* Stack items vertically */
  }

  .optTradeBody1Item {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .optTradeBody1Txt1 {
    font-size: 18px;
  }

  .optTradeBody1Txt2 {
    font-size: 16px;
  }
}
