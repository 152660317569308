.set_container {
  max-width: 960px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 15px;
  /* background-image: linear-gradient(90deg, #000, #383737); */
  border-radius: 4px;
  /* box-shadow: 0 0 10px rgba(255, 255, 255, 0.1); */
  box-shadow: 0 2px 2px rgba(255, 255, 255, 0.1);
  color: #000;
}
.set_container h1 {
  text-align: left;
  margin-bottom: 30px;
  padding: 0.75rem 1.25rem;
  color: #000;
}
.set_card {
  border-radius: 4px;
  overflow: hidden;
}
#BTNN{
  color: #fff;
  border-radius: 10px;
  background-color: #000;
  /* background-image: linear-gradient(45deg, #000, #383737); */
  height: 45px;
  padding: 10px 20px;
  margin-top: 30px;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:hover {
  text-decoration: none;
  background-color: #f5f5f5;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.tab-content {
  padding: 15px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}