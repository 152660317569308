/* Default styles (Normal view) */
.partners {
  display: flex;
  align-items: center;
  padding-top: 80px;
  flex-direction: column;
  padding-bottom: 68px;
}

.partnersTitle {
  color: #12161E;
  font-size: 44px;
  text-align: center;
  font-family: var(--webull-font-family-bold, OpenSans-Bold, Arial, sans-serif) !important;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 12px;
  letter-spacing: 0;
}

.partnersCol {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin-top: 52px;
}

.partnersImg {
  width: 200px;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .partnersCol {
    width: 80%;
  }
  .partnersImg {
    width: 150px;
  }
}

@media (max-width: 768px) {
  .partnersCol {
    flex-wrap: wrap;
    justify-content: center;
  }
  .partnersImg {
    width: 120px;
    margin: 10px;
  }
}

@media (max-width: 480px) {
  .partnersTitle {
    font-size: 36px;
    line-height: 50px;
  }
  .partnersImg {
    width: 100px;
    margin: 8px;
  }
}
