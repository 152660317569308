.pay_container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
}

form .row {
  width: 100%;
  max-width: 700px;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  flex-wrap: wrap;
}

form .row .pay_column {
  flex: 1 1 calc(50% - 15px);
  margin: 10px 0;
}

form .row p {
  font-size: 15px;
}

.pay_column .pay_input-box {
  margin: 15px 0;
}

.pay_input-box span {
  display: block;
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 700;
  
}

.pay_input-box input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid gray;
  border-radius: 6px;
  font-size: 15px;
  /* background-image: linear-gradient(90deg, #000, #383737); */
  color: #000;
}

.pay_select {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid gray;
  border-radius: 6px;
  font-size: 15px;
  /* background-image: linear-gradient(90deg, #000, #383737); */
  color: #000;
}

.pay_select option {
  background-color: #141414;
  color: #fff;
}

.payDisplay {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 700;
  
}

.pay_copy-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #000;
}

form .btn {
  width: 100%;
  padding: 20px;
  color: #ffffff;
  border: none;
  outline: none;
  border-radius: 6px;
  font-size: 17px;
  margin-top: 5px;
  cursor: pointer;
  transition: 0.5s;
  background-color: #000;
  /* background-image: linear-gradient(90deg, #000, #383737); */
}

form .btn:hover {
  color: #fff;
  font-size: 20px; /* Reduced the font size increase on hover */
}

.paid{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  
}

.paidHead{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paidBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 700;
}

.paidBtn{
  margin-top: 30px;
  padding: 10px 20px;
  background-color: #FFF;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  color: #000000;
  border: dotted 1px #000000;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.paidBtn:hover{
  color: #fff;
  background-color: #777777;
}

/* Media Query for smaller screens */
@media only screen and (max-width: 768px) {
  .pay_container {
    /* position: relative; */
    /* top: 0;
    left: 0;
    transform: none; */
    padding: 10px;
  }

  form .row {
    padding: 20px;
  }

  form .row .pay_column {
    flex: 1 1 100%;
    margin-bottom: 15px;
  }

  .payDisplay {
    flex-direction: column;
    align-items: flex-start;
  }

  .pay_copy-btn {
    align-self: flex-start;
    margin-top: 10px;
  }
}
