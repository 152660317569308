
*{
  padding: 0;
  /* margin: 0; */
  box-sizing: border-box;
  text-decoration: none;
  /* min-width: 100%; */
  list-style: none;
  /* align-items: center; */
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.05);
  
}
body {
  justify-content: center;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  
}


