
.dashfooter {
  background-image: linear-gradient(90deg, #000, #383737);
  box-shadow: 0 5px 10px rgba(255, 255, 255, 0.1);
  position: fixed;
  width: 90%;
  bottom: 20px;
  color: white;
  height: 7%;
  /* max-height: 80px; */
  border-radius: 50px;
  padding: 15px;
  display: flex;
  text-align: center;
  align-items: center;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 10;
  justify-content: space-between;
  opacity: 0.8;
}

.dashfooter a {
  text-decoration: none;
  background-color: transparent;
  border: none;
  color: white;
  padding: 10px;
  /* text-align: center; */
  text-decoration: none;
  /* display: inline-block; */
  font-size: 130%;
  /* margin: 4px 2px; */
  border-radius: 50px;
}

.dashfooter a.active {
  color: #5fa3f7;
}
