.trading_card, .trading_card1 {
  border-radius: 5px;
  padding: 20px;
  width: 100%; /* Adjusted width for smaller screens */
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #1f2937;
  color: #fff;
}
.trading_card1 {
  color: #f9f9f9;
  background-image: linear-gradient(45deg, #000, #383737);
  background-color: #383737;
}
hr {
  color: #000;
}
.tradeplan_card_container {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 1fr; /* Single column layout by default */
  gap: 20px;
  text-align: center;
  padding: 28px;
  /* box-shadow: 0 15px 20px bisque; */
  grid-column-gap: 5px; /* Adjust the gap between columns */
}
.tradeplan_btn, .btn1 {
  padding: 10px;
  background-color: #3d495c;
  width: 100%;
  margin-top: 10px;
  font-size: 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
}
.tradeplan_btn:hover, .tradeplan_card_container .btn1:hover {
  border: 1px solid ;
}
.tradeplan_card_container .btn1 {
  /* background-color: #3d495c; */
  background-image: linear-gradient(45deg, #000, #383737);
  color: #fff;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
}
.tradeplan_card_container .btn1:hover {
  border: 1px solid #f9f9f9;
  cursor: pointer;
}
.trading_heading{
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  margin-bottom: 35px;
  /* margin-top: 5rem; */
}
.trading_heading h1{
  font-size: 45px;
  font-weight: 600;
  color: #000;
}
.trading_heading h2{
  font-size: 18px;
  color: #696969;
}
.tradeplan_card_container hr{
  color: #ccc;
}
.trading_title{
  color: #d8d5d5;
  font-size: 18px;
}
.tradeplan_DC, .tradeplan_DC2 {
  font-size: 78px;
  font-weight: bolder;
}
.tradeplan_DC {
  color: #fff;
}
.tradeplan_DC2 {
  background-image: linear-gradient(45deg, #fafafa, #757575);
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* color: #5fa3f7; */
}
.card2 img {
  width: 40px;
  border-radius: 4px;
  margin-right: 15px;
}
.testcontainer{
  justify-content: center;
}

/* Media Queries */
@media screen and (min-width: 768px) {
  .tradeplan_card_container {
      grid-template-columns: repeat(2, 1fr); /* Two-column layout for larger screens */
  }
  .trading_card, .trading_card1 {
      width: auto; /* Reset width to auto for larger screens */
  }
}

@media screen and (min-width: 1024px) {
  .tradeplan_card_container {
      grid-template-columns: repeat(3, 1fr); /* Three-column layout for extra-large screens */
  }
}