/* Default styles (Normal view) */
.optTradeFooter {
  color: #373b3f;
  width: 70%;
  padding: 33px 0 20px;
  font-size: 12px;
  box-sizing: border-box;
  margin: auto;
}

.optTradeFooterRow {
  width: 70%;
  display: flex;
  padding-top: 64px;
  padding-bottom: 7px;
  margin: auto;
  justify-content: space-between;
}

.optTradeFooterCol {
  display: flex;
  font-size: 14px;
  margin-right: 20px;
  flex-direction: column;
}

.optTradeFooterCol a {
  display: block;
  font-size: 14px;
  text-align: left;
  font-family: var(--webull-font-family-normal, OpenSans-Regular, Arial, sans-serif) !important;
  line-height: 19px;
  padding-bottom: 17px;
  text-decoration: none;
  color: rgb(99, 104, 114);
}

.optTradeFooterCol span {
  width: 40px;
  border: 1px solid rgba(199, 203, 209, 0.66);
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  margin-right: 24px;
  border-radius: 50%;
  justify-content: center;
}

.optTradeFooterIcons {
  cursor: initial;
  display: flex;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .optTradeFooter {
    width: 80%;
  }
  .optTradeFooterRow {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .optTradeFooterRow {
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .optTradeFooterCol {
    margin-right: 0;
    margin-bottom: 20px;
    align-items: center;
    text-align: center;
  }

  .optTradeFooterIcons {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .optTradeFooter {
    width: 90%;
    font-size: 12px;
  }

  .optTradeFooterCol a {
    font-size: 12px;
    padding-bottom: 10px;
  }

  .optTradeFooterCol span {
    width: 35px;
    height: 35px;
    margin-right: 16px;
  }
}
