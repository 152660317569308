.loan {
  color: #fff;
  width: 100%;
  /* min-height: 80vh; */
  max-width: 650px;
  padding: 28px;
  margin: auto;
  /* background-image: linear-gradient(45deg, #000, #383737); */
  box-shadow: 0 2px 2px rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  color: #000;
}
.loanheader {
  border-bottom: 1px solid silver;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
}
.loanheader img {
  width: 200px;
}
.loanheader h2 {
  font-size: 32px;
  font-weight: 600;
  text-align: left;
  background-image: linear-gradient(45deg, #5fa3f7, #32547e);
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 8px;
}
@media only screen and (max-width: 768px) {
  .loanheader img {
    width: 150px;
  }
  .loanheader h2 {
    font-size: 1.6rem;
  }
}
@media only screen and (max-width: 480px) {
  .loanheader img {
    width: 100px;
  }
  .loanheader h2 {
    font-size: 1.2rem;
  }
}
.loanforms {
  margin-top: 30px;
  padding-bottom: 50px;
}