.sidebar{
  position: fixed;
  left: -250px;
  width: 250px;
  height: 100%;
  overflow: hidden;
  background-color: #000;
  transition: all .5s ease;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  top: 0px;
  /* z-index: 10000; */
}
.hidescrollbar{
  overflow-y: hidden;
}
.sidebar header{
  font-size: 22px;
  color: white;
  text-align: center;
  line-height: 70px;
  user-select: none;
}
.sidebar ul a{
  display: block;
  height: 100%;
  width: 100%;
  line-height: 65px;
  font-size: 20px;
  color: white;
  padding-left: 40px;
  box-sizing: border-box;
  transition: .4s;
}
.sidebar ul a.active{
  background-image: linear-gradient(45deg, #5fa3f7, #32547e);
  padding-left: 50px;
}
.sidebar li:hover a{
  padding-left: 50px;
  background-image: linear-gradient(45deg, #5fa3f7, #32547e);
}
.sidebar ul a i{
  margin-right: 15px;
}
#sidebar_check{
  display: none;
}
label #btn,label #cancel{
  position: absolute;
  cursor: pointer;
  border-radius: 3px;
}
label #btn{
  left: 40px;
  top: 25px;
  font-size: 35px;
  color: black;
  padding: 6px 12px;
  transition: al l.5s;
}
label #cancel{
  z-index: 1111;
  left: -195px;
  top: 17px;
  font-size: 30px;
  color: #fff;
  padding: 4px 9px;
  transition: all .5s ease;
}
#sidebar_check:checked ~ .sidebar{
  left: 0;
}
#sidebar_check:checked ~ label #btn{
  left: 250px;
  opacity: 0;
  pointer-events: none;
}
#sidebar_check:checked ~ label #cancel{
 left: 195px;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .sidebar {
      width: 200px;
      max-width: 200px;
  }
  .sidebar header {
      font-size: 18px;
      line-height: 50px;
  }
  .sidebar ul a {
      font-size: 18px;
      line-height: 50px;
      padding-left: 30px;
  }
  ul li:hover a{
      padding-left: 40px;
  }
  label #btn {
      left: 30px;
      top: 20px;
      font-size: 30px;
  }
  label #cancel {
      left: -155px;
      top: 14px;
      font-size: 25px;
  }
  #sidebar_check:checked ~ label #btn {
      left: 200px;
  }
  #sidebar_check:checked ~ label #cancel {
      left: 155px;
  }
}