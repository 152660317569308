/* styles/WhatsAppFooter.css */

.whatsAppFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 70px 20px;
  position: fixed;
  bottom: 0;
  z-index: 1;
}

.whatsapp {
  height: 70px;
  cursor: pointer;
}

/* Hide the footer on screens wider than 768px (typical tablet/desktop) */
@media (min-width: 769px) {
  .whatsAppFooter {
    display: none;
  }
}
