/* Default styles (Normal view) */
.optAchievements {
  display: flex;
  align-items: center;
  padding-top: 80px;
  flex-direction: column;
  padding-bottom: 68px;
  text-align: center;
}

.optachi_header {
  color: #12161E;
  font-size: 44px;
  font-family: var(--webull-font-family-bold, OpenSans-Bold, Arial, sans-serif) !important;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 12px;
  letter-spacing: 0;
}

.optachiCol {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin-top: 52px;
}

.optachiCol2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  margin-top: 52px;
}

.optachiImg {
  width: 200px;
}

.optachiGroup {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.optachiText {
  color: #636872;
  font-size: 20px;
  font-family: var(--webull-font-family-normal, OpenSans-Regular, Arial, sans-serif) !important;
  font-weight: 400;
  line-height: 28px;
  margin-top: 12px;
  letter-spacing: 0;
}

.optachiNumber {
  color: #0D71FF;
  font-size: 60px;
  font-family: var(--webull-font-family-extra-bold, OpenSans-Extrabold, Arial, sans-serif) !important;
  font-weight: 900;
  line-height: 72px;
  margin-bottom: 12px;
  letter-spacing: 0;
}

/* Media Queries */

/* For tablets and smaller devices */
@media (max-width: 768px) {
  .optachi_header {
    font-size: 36px;
    line-height: 48px;
  }

  .optachiCol,
  .optachiCol2 {
    width: 80%;
    flex-direction: column; /* Stack elements vertically */
  }

  .optachiGroup {
    margin-bottom: 24px; /* Add spacing between groups */
  }

  .optachiText,
  .optachiNumber {
    font-size: 18px; /* Adjust font size */
    line-height: 24px;
  }

  .optachiImg {
    width: 150px; /* Reduce image size */
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .optachi_header {
    font-size: 28px;
    line-height: 36px;
    padding: 0 20px; /* Add padding */
  }

  .optachiCol,
  .optachiCol2 {
    width: 90%;
  }

  .optachiText,
  .optachiNumber {
    font-size: 16px; /* Further adjust font size */
    line-height: 22px;
  }

  .optachiImg {
    width: 120px; /* Further reduce image size */
  }
}
