.optNavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 80px;
  height: 70px;
  background-color: #ffffffd8;
  backdrop-filter: saturate(180%) blur(10px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1;
}

.optNavLogoImg {
  height: 60px;
}

.optNavLogoText {
  height: 60px;
}

.optNavBtn {
  padding: 10px 20px;
  background-color: #0d71ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.optNavBtn:hover {
  background-color: #7cb2ff;
}

/* Media Queries */

/* For tablets and smaller devices */
@media (max-width: 768px) {
  .optNavbar {
    padding: 10px 40px;
  }

  .optNavLogoImg,
  .optNavLogoText {
    height: 50px;
  }

  .optNavBtn {
    padding: 8px 16px;
    font-size: 14px;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .optNavbar {
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    height: auto;
  }

  .optNavLogo {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .optNavLogoImg,
  .optNavLogoText {
    height: 40px;
  }

  .optNavBtn {
    width: 100%;
    padding: 12px;
    font-size: 10px;
    margin-top: 10px;
  }
}
