/* Default styles (Normal view) */
.optTradeBody2 {
  padding: 10px;
  display: flex;
  background: rgba(216, 231, 252, 0.16);
  align-items: center;
  padding-top: 80px;
  flex-direction: column;
  padding-bottom: 68px;
  text-align: center;
}

.optTradeBody2Title {
  color: #12161E;
  font-size: 44px;
  font-family: var(--webull-font-family-bold, OpenSans-Bold, Arial, sans-serif) !important;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 12px;
  letter-spacing: 0;
}

.optTradeBody2Desc {
  color: #636872;
  width: 100%;
  max-width: 1000px;
  font-size: 20px;
  font-family: var(--webull-font-family-normal, OpenSans-Regular, Arial, sans-serif) !important;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 52px;
  letter-spacing: 0;
  margin: auto; /* Center align the description */
}

.optTradeBody2Table {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  border-radius: 12px;
  border-spacing: 0;
  border-collapse: separate;
  overflow-x: auto; /* Allow horizontal scrolling on smaller screens */
}

.optTradeBody2Thead,
.optTradeBody2Tbody,
.optTradeBody2Tfoot {
  width: 100%;
}

.optTradeBody2tr {
  display: flex;
  flex-direction: row;
}

.optTradeBody2th,
.optTradeBody2td,
.optTradeBody2Tfoottd {
  color: #12161E;
  width: 200px;
  height: 90px;
  font-size: 20px;
  box-sizing: content-box;
  text-align: center;
  border-left: 1.2px solid rgba(255, 255, 255, 1);
  font-family: var(--webull-font-family-normal, OpenSans-Regular, Arial, sans-serif) !important;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(104, 121, 145, 0.08);
}

.optTradeBody2th {
  background: rgba(104, 121, 145, 0.08);
}

.optTradeBody2th:first-child,
.optTradeBody2td:first-child,
.optTradeBody2Tfoottd:first-child {
  border-left: none;
}

.optTradeBody2th:first-child {
  border-top-left-radius: 12px;
}

.optTradeBody2th:last-child {
  border-top-right-radius: 12px;
}

.optTradeBody2td {
  background: #ffffff;
  border-left: 1.2px solid rgba(229, 229, 229, 1);
  border-bottom: 1.2px solid rgba(229, 229, 229, 1);
  height: 84px;
  font-size: 16px;
  font-family: var(--webull-font-family-normal, OpenSans-Regular, Arial, sans-serif) !important;
  line-height: 28px;
  letter-spacing: 0;
}

.optTradeBody2Tfoottd {
  color: #0d71ff;
  height: 84px;
  font-size: 24px;
  background: rgba(13, 113, 255, 0.08);
  font-family: var(--webull-font-family-semi-bold, OpenSans-SemiBold, Arial, sans-serif) !important;
  font-weight: 600;
}

.optTradeBody2Tfoottd:first-child {
  border-bottom-left-radius: 12px;
}

.optTradeBody2Tfoottd:last-child {
  border-bottom-right-radius: 12px;
}

.optTradeBody2Note {
  color: #979ba1;
  font-size: 14px;
  line-height: 22px;
}

/* Button styling */
.optTradeBody2Btn {
  margin: auto;
  margin-top: 32px;
  cursor: pointer;
  padding: 10px 20px;
  background-color: #0d71ff;
  color: #fff;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.optTradeBody2Btn:hover {
  background-color: #004bb5;
}

/* Media Queries */

/* For tablets and smaller devices */
@media (max-width: 768px) {
  .optTradeBody2Title {
    font-size: 36px;
    line-height: 48px;
  }

  .optTradeBody2Desc {
    font-size: 18px;
    line-height: 26px;
    max-width: none; /* Reset max-width */
  }

  .optTradeBody2th,
  .optTradeBody2td,
  .optTradeBody2Tfoottd {
    font-size: 18px;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .optTradeBody2Title {
    font-size: 28px;
    line-height: 36px;
  }

  .optTradeBody2Desc {
    font-size: 16px;
    line-height: 24px;
    padding: 0 20px; /* Padding for smaller screens */
  }

  .optTradeBody2Table {
    overflow-x: scroll; /* Allow horizontal scrolling on smaller screens */
  }

  .optTradeBody2th,
  .optTradeBody2td,
  .optTradeBody2Tfoottd {
    font-size: 14px;
    width: auto;
    min-width: 120px; /* Minimum width for responsive design */
    padding: 10px;
  }

  .optTradeBody2Note {
    font-size: 12px;
  }

  .optTradeBody2Btn {
    font-size: 16px;
    padding: 8px 16px;
  }
}
