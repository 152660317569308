.stat_container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  /* background-image: linear-gradient(45deg, #383737, #000); */
  background-color: #0d71ff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(124, 124, 124, 0.1);
}

.stat_container h1 {
  text-align: center;
  margin-bottom: 30px;
  /* background-image: linear-gradient(90deg, #5fa3f7, #32547e);
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #000;
  padding: 0.75rem 1.25rem;
}

.transaction-table {
  margin-bottom: 30px;
  overflow-x: auto;
}

.stat_container table {
  width: 100%;
  border-collapse: collapse;
}

.stat_container th, .stat_container td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

.stat_container th {
  color: #000;
}

.stat_container tr:nth-child(even) {
  /* background-color: #f1f1f1; */
}

.stat_container tr:hover {
  /* background-color: #e9ecef; */
}

.clear-button {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: block;
  margin: 0 auto;
}

.clear-button:hover {
  background-color: #c82333;
}

@media (max-width: 768px) {
  .stat_container {
      padding: 10px;
      border-radius: 0;
      background-image: none;
      background-color: #ffffff;
  }

  .stat_container h1 {
      font-size: 24px;
  }

  .transaction-table {
      overflow-x: auto;
  }

  .stat_container th, .stat_container td {
      padding: 10px;
  }

  .clear-button {
      margin-top: 20px;
  }
}